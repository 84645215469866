'use client'

import * as Sentry from '@sentry/nextjs'

import { FC, ReactNode, createContext, useCallback, useEffect, useRef, useState } from 'react'

import debounce from 'lodash.debounce'
import isEqual from 'lodash.isequal'
import { Session } from 'next-auth'
import { signIn } from 'next-auth/react'
import { useSnackbar } from 'notistack'

import { useTranslation } from '@/lib/i18n'

import { authBreadcrumb, isValidSession } from './utils'

import { environment } from '../../config/environment'
import { Environment } from '../../const'
import { hasRefreshAccessTokenError } from '../../const/errors'

export const AuthContext = createContext<Session | null>(null)

interface AuthProviderProps {
    children: ReactNode
    session: Session | null
}

const getInitialSession = (session: Session | null): Session | null => {
    if (session) {
        if (hasRefreshAccessTokenError(session)) {
            return null
        }

        return session
    }

    return null
}

export const AuthenticationProvider: FC<AuthProviderProps> = ({ children, session: providedSession }) => {
    const initialSession = getInitialSession(providedSession)
    const env = environment()
    const { enqueueSnackbar } = useSnackbar()
    const { t } = useTranslation('common')

    const [session, setSession] = useState<Session | null>(initialSession)

    const timeoutRef = useRef<NodeJS.Timeout | null>(null)

    const refetch = useCallback(async () => {
        try {
            const response = await fetch('/api/auth/session')

            if (!response.ok) {
                const logMessage = `[${response.status}] Response from session endpoint not okay: ${response.statusText}`

                enqueueSnackbar(t('unexpectedError'), {
                    variant: 'customErrorSnackbar',
                    data: logMessage,
                })

                console.error(logMessage, { extra: { session, response } })

                signIn('keycloak')
            }

            const fetchedSession = await response.json()

            Sentry.addBreadcrumb(
                authBreadcrumb({
                    message: `Fetched session`,
                    session: fetchedSession,
                    source: 'refetch',
                }),
            )

            if (hasRefreshAccessTokenError(fetchedSession)) {
                setSession(null)
            } else {
                // Update session only if it differs from the fetched session
                // to avoid unnecessary re-renders
                if (!isEqual(session, fetchedSession)) {
                    setSession(fetchedSession)
                }
            }
        } catch (error) {
            const logMessage = 'Failed to fetch from session endpoint'

            enqueueSnackbar(t('unexpectedError'), {
                variant: 'customErrorSnackbar',
                data: logMessage,
            })

            console.error(logMessage, { extra: { session, error } })

            setSession(null)
        }
    }, [enqueueSnackbar, session, t])

    // Handle `focus` and `visibilitychange` events triggered by tab switches in some browsers.
    // Debouncing ensures this function executes only once to refetch the token if needed.
    const handleVisibilityChange = debounce(
        () => {
            if (!document.hidden) {
                // Check for expired token. If expired, attempt to refresh the session by
                // refetching it from the server.
                if (session && session.expires_at < Date.now()) {
                    Sentry.addBreadcrumb(
                        authBreadcrumb({
                            message: `Session expired: ${session.expires_at < Date.now()}`,
                            session,
                            source: 'handleVisibilityChange',
                        }),
                    )

                    refetch()
                }
            }
        },
        250,
        { maxWait: 1000 },
    )

    useEffect(() => {
        if (isValidSession(session)) {
            // Refetch five minutes before the actual expiration time.
            // Server refreshes a token which has ten minutes or less until expiration.
            const remaining = session.expires_at - Date.now() - 5 * 60 * 1000

            Sentry.addBreadcrumb(authBreadcrumb({ message: `Session expires in ${remaining} ms`, session, source: 'remaining check' }))

            // Only set timeout, if the session has a valid token which is not expired
            if (remaining > 0) {
                timeoutRef.current = setTimeout(refetch, remaining)
            }
        }

        return () => {
            // Let's clear the previous timeout just in case it is still active
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current)
            }
        }
    }, [refetch, session])

    useEffect(() => {
        // Do not attach event listeners in local environment
        if (env === Environment.local) {
            return
        }
        // Validate and potentially refresh the session by refetching from the server
        // when the user refocuses the window or switches back to the tab.
        document.addEventListener('visibilitychange', handleVisibilityChange)
        window.addEventListener('focus', handleVisibilityChange)

        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange)
            window.removeEventListener('focus', handleVisibilityChange)
        }
    }, [handleVisibilityChange, env])

    return <AuthContext.Provider value={session}>{children}</AuthContext.Provider>
}
