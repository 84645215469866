import { Environment, Right, Role } from './enum'

import { environment } from '../config/environment'

const ROLE_RIGHTS_PROD = {
    [Role.ADMIN]: Object.values(Right),

    [Role.EDITOR]: [
        Right.ACADEMY_INFO_R,
        Right.GG_AND_ACADEMY_INFO_U,
        Right.GG_INFO_R,
        Right.LICENCE_CONTRACT_ACTIVE_CONTACT_U,
        Right.LICENCE_CONTRACT_ACTIVE_EXTENSION_U,
        Right.LICENCE_CONTRACT_ACTIVE_FEE_U,
        Right.LICENCE_CONTRACT_ACTIVE_U,
        Right.LICENCE_CONTRACT_CHANGE_STATUS,
        Right.LICENCE_CONTRACT_DRAFT_C,
        Right.LICENCE_CONTRACT_DRAFT_CONTACT_U,
        Right.LICENCE_CONTRACT_DRAFT_EXTENSION_U,
        Right.LICENCE_CONTRACT_DRAFT_FEE_U,
        Right.LICENCE_CONTRACT_DRAFT_U,
        Right.LICENCE_CONTRACT_FEE_R,
        Right.LICENCE_CONTRACT_TRANSFERNOTE_R,
    ],
    [Role.RELATIONSHIP_MANAGER]: [
        Right.LEGAL_ENTITY_DRAFT_C,
        Right.LEGAL_ENTITY_DRAFT_U,
        Right.LICENCE_AREA_UPLOAD,
        Right.LICENCE_AREA_DOWNLOAD,
        Right.LICENCE_CONTRACT_DRAFT_C,
        Right.LICENCE_CONTRACT_DRAFT_CONTACT_U,
        Right.LICENCE_CONTRACT_DRAFT_EXTENSION_U,
        Right.LICENCE_CONTRACT_DRAFT_FEE_U,
        Right.LICENCE_CONTRACT_DRAFT_U,
        Right.LICENCE_CONTRACT_FEE_R,
        Right.PROPERTY_LOUNGE_C,
        Right.PROPERTY_LOUNGE_U,
        Right.REQUEST_LEGAL_ENTITY_APPROVED_U,
        Right.REQUEST_LICENCE_CONTRACT_APPROVAL,
        Right.REQUEST_PLANNED_SHOP,
        Right.SHOP_U,
    ],
    [Role.ACCOUNTING]: [Right.LEGAL_ENTITY_APPROVAL, Right.LEGAL_ENTITY_APPROVED_U, Right.LEGAL_ENTITY_DRAFT_U],
    [Role.CONTROLLING]: [Right.LICENCE_CONTRACT_FEE_R],
    [Role.GG]: [Right.GG_INFO_R],
    [Role.ACADEMY]: [Right.ACADEMY_INFO_R],
} as Record<Role, Array<Right>>

const ROLE_RIGHTS_LOWER_ENVS = {
    [Role.DEVELOPER]: Object.values(Right),
    [Role.ADMIN]: Object.values(Right),

    [Role.EDITOR]: [
        Right.ACADEMY_INFO_R,
        Right.GG_AND_ACADEMY_INFO_U,
        Right.GG_INFO_R,
        Right.LICENCE_CONTRACT_ACTIVE_CONTACT_U,
        Right.LICENCE_CONTRACT_ACTIVE_EXTENSION_U,
        Right.LICENCE_CONTRACT_ACTIVE_FEE_U,
        Right.LICENCE_CONTRACT_ACTIVE_U,
        Right.LICENCE_CONTRACT_CHANGE_STATUS,
        Right.LICENCE_CONTRACT_DRAFT_C,
        Right.LICENCE_CONTRACT_DRAFT_CONTACT_U,
        Right.LICENCE_CONTRACT_DRAFT_EXTENSION_U,
        Right.LICENCE_CONTRACT_DRAFT_FEE_U,
        Right.LICENCE_CONTRACT_DRAFT_U,
        Right.LICENCE_CONTRACT_FEE_R,
        Right.LICENCE_CONTRACT_TRANSFERNOTE_R,
    ],
    [Role.RELATIONSHIP_MANAGER]: [
        Right.LEGAL_ENTITY_DRAFT_C,
        Right.LEGAL_ENTITY_DRAFT_U,
        Right.LICENCE_AREA_DOWNLOAD,
        Right.LICENCE_AREA_UPLOAD,
        Right.LICENCE_CONTRACT_DRAFT_C,
        Right.LICENCE_CONTRACT_DRAFT_CONTACT_U,
        Right.LICENCE_CONTRACT_DRAFT_EXTENSION_U,
        Right.LICENCE_CONTRACT_DRAFT_FEE_U,
        Right.LICENCE_CONTRACT_DRAFT_U,
        Right.LICENCE_CONTRACT_FEE_R,
        Right.PROPERTY_LOUNGE_C,
        Right.PROPERTY_LOUNGE_U,
        Right.REQUEST_LEGAL_ENTITY_APPROVED_U,
        Right.REQUEST_LICENCE_CONTRACT_APPROVAL,
        Right.REQUEST_PLANNED_SHOP,
        Right.SHOP_U,
    ],
    [Role.ACCOUNTING]: [Right.LEGAL_ENTITY_APPROVAL, Right.LEGAL_ENTITY_APPROVED_U, Right.LEGAL_ENTITY_DRAFT_U],
    [Role.CONTROLLING]: [Right.LICENCE_CONTRACT_FEE_R],
    [Role.GG]: [Right.GG_INFO_R],
    [Role.ACADEMY]: [Right.ACADEMY_INFO_R],
} as Record<Role, Array<Right>>

const env = environment()

export const RoleRights = env === Environment.prod ? ROLE_RIGHTS_PROD : ROLE_RIGHTS_LOWER_ENVS
